import React from 'react';
import InputTextArea from '../../general/InputTextArea';
import RightsWrapper from '../../sessionProvider/RightsWrapper';
import Date from '../../general/Date';
import DateTime from '../../general/DateTime';
import EmailLink from '../../general/EmailLink';
import PhoneLink from '../../general/PhoneLink';
import InfoAttributeGroup from '../../general/InfoAttributeGroup';
import EmployeesSelect from '../../project/EmployeesSelect';
import ChannelOptionsDisplay from '../../project/ChannelOptionsDisplay';
import QuotationDivisibilitySelect from '../../project/QuotationDivisibilitySelect';
import {QuotationDetail} from '../../../constants/propTypesDefinitions';
import {RIGHTS} from '../../../constants/Rights';
import {GLOBAL_DATA} from '../../../constants/globalData';
import {merge} from '../../../lib/object';
import {isQuotationSend, isTradeSell} from '../../../lib/project';
import withDataHOC from '../../dataProvider/withDataHOC';
import {Trans, t} from '@lingui/macro';
import {createFetchQuotationEdit} from '../../../backend/apiCalls';
import InputText from '../../general/InputText';
import { formatString } from '../../../lib/formatting';
import CustomerLink from '../../general/CustomerLink';
import UserLink from '../../general/UserLink';
import UserSelectByCustomer from '../../project/UserSelectByCustomer';
import CustomersSelect from '../../project/CustomersSelect';
import TransportTypeSelect from '../../project/TransportTypeSelect';
import PaymentMethodSelect from '../../project/PaymentMethodSelect';
import InputQuantity from '../../project/InputQuantity';
import NamePrice from '../../general/NamePrice';
import DateTimePicker from '../../general/DateTimePicker';
import CurrencySelect from '../../project/CurrencySelect';
import InputNumber from '../../general/InputNumber';
import { SESSION_ATTRIBUTES } from '../../sessionProvider/SessionProvider';
import withSessionHOC from '../../sessionProvider/withSessionHOC';
import InputPrice from '../../project/InputPrice';
import { notification } from 'antd';

/**
 * @fero
 */

class QuotationDetailsInfo extends React.PureComponent {
    static propTypes = {
        quotationDetails: QuotationDetail.isRequired,
    };

    onDealerChange = (dealerId) => {
        this.editQuotation({id_dealer: dealerId});
    };

    onUserChange = (userId) => {
        this.editQuotation({id_user: userId});
    };

    onCustomerChange = (customerId) => {
        this.editQuotation({id_customer: customerId});
    };

    onCustomerNoticeChange = (newNotice) => {
        this.editQuotation({customer_notices: newNotice});
    };

    onCustomerReferenceChange = (newRef) => {
        this.editQuotation({customer_reference: newRef});
    };

    onDivisibilityChange = (newDivisibility) => {
        this.editQuotation({divisibility_level: newDivisibility});
    };

    onTransportTypeChange = (newType) => {
        this.editQuotation({id_transport_type: newType});
    };

    onTransportPriceChange = (newPrice) => {
        this.editQuotation({transport_price: newPrice});
    };

    onPaymentMethodChange = (newType) => {
        this.editQuotation({id_payment_method: newType});
    };

    onPaymentPriceChange = (newPrice) => {
        this.editQuotation({payment_price: newPrice});
    };

    onDealerNoticeChange = (newNotice) => {
        this.editQuotation({dealer_notices: newNotice});
    };

    onSentAtChange = (newDate) => {
        this.editQuotation({sent_at: newDate});
    };

    onCurrencyChange = (newCurrency) => {
        this.editQuotation({id_currency: newCurrency});
    };

    onExchangeRateChange = (newRate) => {
        this.editQuotation({exchange_rate: newRate});
    };

    editQuotation = (queryParams) => {
        const {quotationDetails} = this.props;
        const reload = this.props[GLOBAL_DATA.RELOAD_DATA];
        const fetchHandler = this.props[GLOBAL_DATA.FETCH_HANDLER];
        fetchHandler(createFetchQuotationEdit(),
            merge({id: quotationDetails.id}, queryParams),
            null,
            () => reload([GLOBAL_DATA.QUOTATION_DETAILS]),
            (error) => {
                notification['error']({ 
                    message: error.message,
                    duration: 10,
                });
            }
        );
    };

    render() {
        const {quotationDetails = {}, [SESSION_ATTRIBUTES.SETTINGS]: settings} = this.props;
        const customerChecked = quotationDetails.customer != null ? quotationDetails.customer : {};
        const userChecked = quotationDetails.user != null ? quotationDetails.user : {};
        const dealerChecked = quotationDetails.dealer != null ? quotationDetails.dealer : {};
        const isEditable = !isQuotationSend(quotationDetails); 
        return <div className="d-flex flex-wrap justify-content-center">
            <RightsWrapper from={RIGHTS.MARKETING}>
                <InfoAttributeGroup
                    attributes={[
                        {
                            title: <Trans>Organizácia: </Trans>,
                            value: isEditable ? 
                                <CustomersSelect
                                    value={customerChecked.id}
                                    onChange={this.onCustomerChange}
                                />
                                : <CustomerLink
                                    customerId={customerChecked.id}
                                    customerName={customerChecked.name}
                                />
                        },
                        {
                            title: <Trans>IČO: </Trans>,
                            value: formatString(customerChecked.ico)
                        },
                        {
                            title: <Trans>IČ DPH: </Trans>,
                            value: formatString(customerChecked.ic_dph)
                        },
                        {
                            title: <Trans>Zodpovedný obchodník: </Trans>,
                            value: customerChecked.salesman
                        },
                        {
                            title: <Trans>Logista: </Trans>,
                            value: customerChecked.salesman2
                        },
                        {
                            title: <Trans>Vybavuje: </Trans>,
                            value: [
                                <RightsWrapper key="manager" from={RIGHTS.SUPERVISOR}>
                                    <EmployeesSelect
                                        value={dealerChecked.id}
                                        onChange={this.onDealerChange}
                                    />
                                </RightsWrapper>,
                                <RightsWrapper key="dealer" to={RIGHTS.DEALER}>
                                    {dealerChecked.fullname != null ? dealerChecked.fullname : null}
                                </RightsWrapper>
                            ]
                        },
                        {
                            value: <ChannelOptionsDisplay 
                                name={customerChecked.channel} 
                                options={quotationDetails.options} 
                                args={quotationDetails.args} 
                                onEdit={this.editQuotation}
                            />
                        }
                    ]}
                />
                <InfoAttributeGroup
                    attributes={[
                        {
                            title: <Trans>Kontakt: </Trans>,
                            value: isEditable ? 
                            <UserSelectByCustomer
                                customerId={customerChecked.id}
                                value={userChecked.id}
                                onChange={this.onUserChange}
                                allowClear={true}
                            /> :
                            <UserLink
                                userId={userChecked.id}
                                userName={userChecked.fullname}
                            />
                        },
                        {
                            title: <Trans>Email: </Trans>,
                            value: <EmailLink email={userChecked.e_mail}/>
                        },
                        {
                            title: <Trans>Telefón: </Trans>,
                            value: <PhoneLink phone={userChecked.phone}/>
                        },
                        {
                            title: <Trans>Deliteľnosť: </Trans>,
                            value: <QuotationDivisibilitySelect
                                value={quotationDetails.divisibility_level}
                                onChange={this.onDivisibilityChange}
                            />
                        },
                        {
                            title: <Trans>Spôsob dopravy: </Trans>,
                            value: <TransportTypeSelect
                                value={quotationDetails.id_transport_type}
                                onChange={this.onTransportTypeChange}
                                allowClear={true}
                                displayPrice={false}
                            />
                        },
                        {
                            title: <Trans>Cena dopravy: </Trans>,
                            value: <InputPrice
                                value={quotationDetails.transport_price}
                                onChange={this.onTransportPriceChange}
                                withVAT={false}
                            />
                        },
                        {
                            title: <Trans>Spôsob platby: </Trans>,
                            value: <PaymentMethodSelect
                                value={quotationDetails.id_payment_method}
                                onChange={this.onPaymentMethodChange}
                                allowClear={true}
                                displayPrice={false}
                            />
                        },
                        {
                            title: <Trans>Poplatok za platbu: </Trans>,
                            value: <InputPrice
                                value={quotationDetails.payment_price}
                                onChange={this.onPaymentPriceChange}
                                withVAT={false}
                            />
                        },
                        {
                            title: <Trans>Odoslané: </Trans>,
                            value: isEditable ?
                            <DateTimePicker
                                value={quotationDetails.sent_at}
                                onChange={this.onSentAtChange}
                            /> :
                            <DateTime 
                                timeString={quotationDetails.sent_at}
                                nullOption={<Trans>rozpracované</Trans>}
                            />
                        },
                    ]}
                />
                <InfoAttributeGroup
                    attributes={[
                        {
                            title: <Trans>Číslo ponuky u dodávateľa: </Trans>,
                            value: isEditable ?
                            <InputText
                                size="small"
                                value={quotationDetails.customer_reference}
                                onChange={this.onCustomerReferenceChange}
                            /> : quotationDetails.customer_reference,
                            isHidden: isTradeSell(quotationDetails.trade),
                        },
                        {
                            title: <Trans>Cenová hladina: </Trans>,
                            value: quotationDetails.id_price_level != null ? 
                                <CustomerLink
                                    customerId={quotationDetails.id_price_level}
                                    customerName={quotationDetails.price_level}
                                /> : null,
                            isHidden: (quotationDetails.id_price_level == null)
                        },
                        {
                            title: <Trans>Poznámka: </Trans>,
                            value: <InputTextArea
                                size="small"
                                value={quotationDetails.customer_notices}
                                onChange={this.onCustomerNoticeChange}
                            />
                        },
                        {
                            title: <Trans>Interná poznámka: </Trans>,
                            value: <InputTextArea
                                size="small"
                                value={quotationDetails.dealer_notices}
                                onChange={this.onDealerNoticeChange}
                            />
                        },
                        {
                            title: <Trans>Platí do: </Trans>,
                            value: <Date dateString={quotationDetails.active_to} nullOption={<Trans>odvolania</Trans>}/>
                        },
                        {
                            title: <Trans>Dôvod ukončenia: </Trans>,
                            value: quotationDetails.expiration_notices,
                        },
                        {
                            title: <Trans>Posledná zmena: </Trans>,
                            value: <DateTime timeString={quotationDetails.last_change}/>
                        },
                        {
                            title: <Trans>Mena:</Trans>,
                            value: <div className="d-flex align-items-center">
                                 <CurrencySelect
                                    value={quotationDetails.id_currency}
                                    onChange={this.onCurrencyChange}
                                    disabled={!isEditable}

                                />
                                { quotationDetails.id_currency != settings.default_currency ? 
                                    <div className="d-flex align-items-center">
                                        <span className="ml-3 mr-2 text-nowrap">{'1 ' + settings.default_currency + ' ='}</span>
                                        <InputNumber
                                            className="text-right"
                                            value={quotationDetails.exchange_rate}
                                            onChange={this.onExchangeRateChange}
                                            disabled={!isEditable}
                                            after={<span className="ml-2">{quotationDetails.id_currency}</span>}
                                        />
                                    </div> : null
                                }
                             </div>
                        }
                    ]}
                />
            </RightsWrapper>
            <RightsWrapper to={RIGHTS.DISTRIBUTOR}>
                <InfoAttributeGroup
                    attributes={[
                        {
                            title: <Trans>Predajca: </Trans>,
                            value: dealerChecked.fullname
                        },
                        {
                            title: <Trans>Email: </Trans>,
                            value: <EmailLink email={dealerChecked.e_mail}/>
                        },
                        {
                            title: <Trans>Telefón: </Trans>,
                            value: <PhoneLink phone={dealerChecked.phone}/>
                        },
                    ]}
                />
                <InfoAttributeGroup
                    attributes={[
                        {
                            title: <Trans>Odoslané: </Trans>,
                            value: <Date dateString={quotationDetails.sent_at}/>
                        },
                        {
                            title: <Trans>Platí do: </Trans>,
                            value: <Date dateString={quotationDetails.active_to} nullOption={<Trans>odvolania</Trans>}/>
                        },
                        {
                            title: <Trans>Deliteľnosť: </Trans>,
                            value: quotationDetails.divisibility_name,
                        },
                        {
                            title: <Trans>Doprava: </Trans>,
                            value: <NamePrice namePrice={{
                                    name: quotationDetails.transport_type,
                                    price: quotationDetails.transport_price
                                }}
                            />
                        },
                        {
                            title: <Trans>Platba: </Trans>,
                            value: <NamePrice namePrice={{
                                    name: quotationDetails.payment_method,
                                    price: quotationDetails.payment_price
                                }}
                            />
                        },
                    ]}
                />
                <InfoAttributeGroup
                    attributes={[
                        {
                            title: <Trans>Cenová hladina: </Trans>,
                            value: quotationDetails.price_level,
                            isHidden: (quotationDetails.id_price_level == null)
                        },
                        {
                            title: <Trans>Poznámka: </Trans>,
                            value: <pre>{quotationDetails.customer_notices}</pre>
                        },
                    ]}
                />
            </RightsWrapper>
        </div>;
    }

}

export default withSessionHOC([SESSION_ATTRIBUTES.SETTINGS])(
    withDataHOC([GLOBAL_DATA.RELOAD_DATA, GLOBAL_DATA.FETCH_HANDLER])(QuotationDetailsInfo)
);